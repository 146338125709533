import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

function CookieBanner({ language }) {
  const [visible, setVisible] = useState(true);

  if (!visible) return <></>;

  if (Cookies.get("ga-cookie-consent") == "true") return <></>;

  return (
    <div className="cookie-banner-w">
      <p>
        {language === "it"
          ? 'Usiamo i cookies per migliorare la tua esperienza sul sito, offire pubblicità personalizzate e analizzare il traffico. Cliccando su "Accetto", acconsenti al nostro uso dei cookies.'
          : 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.'}
      </p>
      <div style={{ width: 235 }}>
        <button
          onClick={() => {
            Cookies.set("ga-cookie-consent", false);
            setVisible(false);
          }}
          id="cookie-banner-deny"
        >
          {language === "it" ? "Rifiuta" : "Reject All"}
        </button>
        <button
          id="cookie-banner-accept"
          onClick={() => {
            Cookies.set("ga-cookie-consent", true, { expires: 900 });
            setVisible(false);
          }}
        >
          {language === "it" ? "Accetta" : "Accept All"}
        </button>
      </div>
    </div>
  );
}

export default CookieBanner;
