import React, { useState, useEffect } from "react";
import "../styles/style.sass";
import Cookies, { get } from "js-cookie";
import It from "../translations/it.yaml";
import { CloseOutlined } from "@ant-design/icons";

function DonationBanner({ language, close }) {
  const [donationBanner, setDonationBanner] = useState(false);
  const [ready, setReady] = useState(false);

  const [amount, setAmount] = useState(70);

  const [actualAmount, setActualAmount] = useState(164);

  const total = 1000;

  const barlength = (actualAmount / total) * 100 + "%";
  var newbarlength = (amount / total) * 100 + "%";

  const donationLinks = {
    5: "https://buy.stripe.com/5kA9C17gYgGI6K4aEE",
    9: "https://buy.stripe.com/5kAg0pdFm4Y0fgAeUV",
    25: "https://buy.stripe.com/5kA15v0SAgGIc4obIK",
    70: "https://buy.stripe.com/8wMbK97gY7689Wg3cf",
    150: "https://buy.stripe.com/4gw3dDbxe9eg7O8004",
  };

  return (
    <div className="sustain-banner-w">
      <button id="closebtn" onClick={() => close()}>
        <CloseOutlined style={{ fontSize: 20, color: "white" }} />
      </button>
      <div className="sustain-banner">
        <div>
          <p
            style={{
              borderBottom: "1px solid #e4cc76",
              paddingBottom: 10,
              marginBottom: 30,
            }}
          >
            {language === "it" ? "Sostieni la cultura" : "Support culture"}
            <br></br>
            {language === "it" ? "con una donazione" : "with a donation"}
          </p>
          <p
            style={{
              fontSize: 16,
              lineHeight: "18px",
              marginTop: 10,
              fontFamily: "sans-serif",
              fontWeight: 300,
              color: "#f0f1f2",
            }}
          >
            {language === "it"
              ? "Abbiamo bisogno del tuo aiuto per continuare a pubblicare contenuti"
              : "We need your contribution to continue publishing content"}
          </p>
        </div>
        <div className="sustain-right">
          <p id="sustain-results">
            €{actualAmount}
            <span style={{ fontSize: 16, color: "grey" }}> / €{total}</span>
          </p>
          <div className="sustain-bar-w">
            <div
              style={{ width: barlength, zIndex: 1, position: "relative" }}
              className="sustain-bar"
            ></div>
            <span
              style={{
                width: newbarlength,
                position: "relative",
                marginLeft: -12,
                backgroundColor: "white",
                zIndex: 0,
                paddingRight: 12,
              }}
              className="sustain-bar"
            ></span>
          </div>
          <div className="choose-amount-donation-banner">
            <button
              className={amount === 5 && "selected"}
              onMouseEnter={() => setAmount(5)}
            >
              5 €
            </button>
            <button
              className={amount === 9 && "selected"}
              onMouseEnter={() => setAmount(9)}
            >
              9 €
            </button>
            <button
              className={amount === 25 && "selected"}
              onMouseEnter={() => setAmount(25)}
            >
              25 €
            </button>
            <button
              className={amount === 70 && "selected"}
              onMouseEnter={() => setAmount(70)}
            >
              70 €
            </button>
            <button
              className={amount === 150 && "selected"}
              onMouseEnter={() => setAmount(150)}
            >
              150 €
            </button>
          </div>
          <a href={donationLinks[amount]}>
            <button className="donate-btn">
              {language === "it" ? "Dona ora " : "Donate now "}
              ❤️
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default DonationBanner;
